import React from 'react'

import { Link } from 'gatsby'
import styles from "./SocialButtons.module.styl"
import Obfuscate from "react-obfuscate";

import linkedin from "../../content/assets/social/linkedin.svg"
import xing from "../../content/assets/social/xing.svg"
import twitter from "../../content/assets/social/twitter.svg"
import github from "../../content/assets/social/github.svg"
import email from "../../content/assets/social/email.svg"

export default function SocialButtons(props) {
  const mini = props.mini

  if(mini) {
    return (
      <ul className={ styles.social }>
        <li><a href="http://twitter.com/feldberblin/"><img src={twitter} alt="Twitter" /></a></li>
        <li><a href="https://www.linkedin.com/company/feldberlin"><img src={linkedin} alt="LinkedIn" /></a></li>
        <li><Obfuscate email="hello@feldberlin.com"><img src={email} alt="Email" /></Obfuscate></li>
      </ul>
    )
  } else {
    return (
      <ul className={ styles.social }>
        <li><a href="http://twitter.com/feldberlin/"><img src={twitter} alt="Twitter" /></a></li>
        <li><a href="http://github.com/feldberlin/"><img src={github} alt="Github" /></a></li>
        <li><a href="https://www.linkedin.com/company/feldberlin"><img src={linkedin} alt="LinkedIn" /></a></li>
        <li><Obfuscate email="hello@feldberlin.com"><img src={email} alt="Email" /></Obfuscate></li>
      </ul>
    )
  }
}
