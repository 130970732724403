/* Website footer
 *
 */

import React from 'react'
import { Link } from 'gatsby'
import Obfuscate from "react-obfuscate";

import Calendly from '../components/Calendly'
import ContactForm from '../components/ContactForm'
import Signup from '../components/Signup'
import SocialButtons from '../components/SocialButtons'
import styles from "./Footer.module.styl"


export function Footer(props) {
  if(props.kind == "newsletter") {
    return <FooterNewsletterSub />
  } else if(props.kind == "minimal") {
    return <FooterMinimal />
  } else if(props.kind == "basic") {
    return <FooterBasic />
  } else {
    return <FooterMain />
  }
}

export function FooterMain() {
  return (
    <div className={ styles.containerMain }>
      <footer className={ styles.footer }>
        <div className={ styles.contact }>
          <ContactForm />
        </div>
        <div className={ styles.links }>
          <Work />
          <AboutLinks />
          <SocialButtons />
        </div>
      </footer>
    </div>
  )
}

export function FooterNewsletterSub() {
  return (
    <div className={ styles.containerNewsletterSub }>
      <footer className={ styles.footer }>
        <div className={ styles.signup }>
          <Signup size={ "long" } />
        </div>
        <div className={ styles.usp }>
          <p>
            We have written about topics like data privacy, generative
            modelling and data platforms. Read about data foundations and
            research engineering in our newsletter.
          </p>
          <p>
            <Link to="/articles">Check out the last few articles →</Link>
          </p>
        </div>
        <div className={ styles.work }>
          <Work />
        </div>
        <div className={ styles.about }>
          <AboutLinks />
        </div>
        <div className={ styles.contact }>
          <h3>Contact</h3>
          <div>
            Use the contact form or send an email to <Obfuscate email="hello@feldberlin.com" />. You can also schedule a zoom call <Calendly text="right here." link={ true } />
          </div>
          <SocialButtons />
        </div>
      </footer>
    </div>
  )
}

export function FooterBasic() {
  return (
    <div className={ styles.containerBasic }>
      <footer className={ styles.footer }>
        <div className={ styles.work }>
          <Work />
        </div>
        <div className={ styles.about }>
          <AboutLinks />
        </div>
        <div className={ styles.contact }>
          <h3>Contact</h3>
          <div>
            Use the contact form or send an email to <Obfuscate email="hello@feldberlin.com" />. You can also schedule a zoom call <Calendly text="right here." link={ true } />
          </div>
          <SocialButtons />
        </div>
      </footer>
    </div>
  )
}

export function FooterMinimal() {
  return (
    <div className={ styles.containerMinimal }>
      <footer className={ styles.footer }>
        <div>FELD Berlin { new Date().getFullYear() }</div>
        <div className={ styles.links }>
          <AboutLinks minimal={ true } />
          <SocialButtons mini={ true } />
        </div>
      </footer>
    </div>
  )
}

// Helper components

export function Work(props) {
  return (
    <div>
      <h3>Work with FELD</h3>
      <ul>
        <li><Link to="/services/research-engineering">Research engineering</Link></li>
        <li><Link to="/services/data-foundations">Data foundations</Link></li>
      </ul>
    </div>
  )
}

export function AboutLinks(props) {
  if(props.minimal) {
    return (
      <ul>
        <li><Link to="/about">Team</Link></li>
      </ul>
    )
  } else {
    return (
      <div>
        <h3>About</h3>
        <ul>
          <li><Link to="/about">Team</Link></li>
        </ul>
      </div>
    )
  }
}
