import React from 'react';
import { PopupWidget } from "react-calendly"
import styles from "./Calendly.module.styl"

class Calendly extends React.Component {
  constructor(props) {
    super(props);
  }

  submit(ev) {
    document
      .getElementsByClassName('calendly-badge-content')[0]
      .click()
  }

  render() {
    let className = this.props.link ? styles.calendlyLink : styles.calendly
    return (
      <div className={ className }>
        <button onClick={ this.submit }>{ this.props.text }</button>
        <PopupWidget url="https://calendly.com/feldberlin" />
      </div>
    )
  }
}

export default Calendly
