import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Header from '../components/Header'
import { Footer } from '../components/Footer'
import styles from "./Layout.module.styl"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const footer = this.props.footer
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div className={ styles.layout }>
        <Helmet
          extraMeta={[
            {
              name: `apple-mobile-web-app-status-bar-style`,
              content: `default`,
            }
          ]} />
        <div className={ styles.top }>
          <Header />
          {children}
        </div>
        <div className={ styles.bottom }>
          <Footer kind={ footer } />
        </div>
      </div>
    )
  }
}

export default Layout
