import React from 'react'
import Image from 'gatsby-image'
import Obfuscate from "react-obfuscate";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Calendly from "./Calendly"

import firebase from '../utils/firebase'
import styles from "./ContactForm.module.styl"

function Error(props) {
  return (
    <div className={ styles.error }>
      { props.children }
    </div>
  )
}

export default class ContactForm extends React.Component {
  Apology = "Looks like we can't take your message right now. Please send an email to hi@feldberlin.com instead. Sorry about this!"

  constructor(props) {
    super(props);
    this.state = { created: false }
  }

  createContact(props) {
    return import('firebase/firestore').then(function() {
      return firebase
        .firestore()
        .collection("contacts")
        .add({
          name: props.name,
          email: props.email,
          message: props.message,
        })
    })
    .catch((err) => { throw new Error(err) })
  }

  render() {
    if(this.state.created) {
      return (
        <div className={ styles.contactForm }>
          <div className={ styles.created }>
            <h3>Thanks for your message.</h3>
            <p>
              We'll get back to you as soon as we can. ⛷
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={ styles.contactForm }>
          { !this.props.bare ?
            <div>
              <h2>Contact</h2>
              <div>
                Use the contact form or send an email to <Obfuscate email="hello@feldberlin.com" />. You can also schedule a zoom call <Calendly text="right here." link={ true } />
              </div>
            </div>
          : '' }

            <Formik
              initialValues={{ name: '', email: '', message: '' }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Hmm, this doesn't look like an email address.")
                  .required("We'll need your email address."),
                message: Yup.string()
                  .required("How can we help you?")
              })}
              onSubmit={(values, actions) => {
                setTimeout(async () => {
                  await this.createContact(values)
                    .then(() => {
                      actions.setSubmitting(false);
                      this.setState({ created: true });
                    })
                    .catch((err) => {
                      this.setState({ created: false });
                      actions.setStatus({ error: this.Apology });
                      actions.setSubmitting(false);
                    })
                }, 400);
              }}
            >

              {({ isSubmitting, status }) => (
                <Form>
                  <label htmlFor="name">Name</label>
                  <Field name="name" type="text" autoComplete="off" spellCheck="false" />
                  <ErrorMessage name="name" component={ Error } />

                  <label htmlFor="email">Email</label>
                  <Field name="email" type="email" autoComplete="off" spellCheck="false" />
                  <ErrorMessage name="email" component={ Error } />

                  <label htmlFor="message">Message</label>
                  <Field name="message" as="textarea" rows="8" autoComplete="off" spellCheck="false" />
                  <ErrorMessage name="message" component={ Error } />
                  <span>{ status && status.error ? status.error : '' }</span>
                  <span>{ isSubmitting ? 'saving...' : '' }</span>
                  <input type="submit" value="Send" disabled={ isSubmitting } />
                </Form>
              )}
            </Formik>
        </div>
      )
    }
  }
}
