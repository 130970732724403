/* Website header
 *
 */

import React from 'react'
import { Link } from "gatsby"

import Nav from './Nav'

import logo from "../../content/assets/logo.svg"
import styles from "./Header.module.styl"

export default function Header() {
  return (
    <div className={ styles.header }>
      <header>
        <div>
          <Link to="/"><img src={logo} alt="Logo" /></Link>
          <span>a&nbsp;data&nbsp;science&nbsp;company.</span>
        </div>
        <Nav />
      </header>
    </div>
  )
}
