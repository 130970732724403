import React from 'react'

import styles from "./Hamburger.module.styl"

const baseStyle = styles.hamburger
const activeStyle = [baseStyle, styles.isActive].join(' ')

export default class Hamburger extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={ this.props.active ? activeStyle : baseStyle } onClick={ this.props.handleToggle }>
        <button className={ styles.menu } type="button">
          <span className={ styles.box }>
            <span className={ styles.inner }></span>
          </span>
        </button>

        <div className={ styles.label }>
          { this.props.active ? 'Close': 'Menu' }
        </div>
      </div>
    )
  }
}
