/* Website Navigation
 *
 */

import React from 'react'
import { Link } from "gatsby"

import Calendly from './Calendly'
import Hamburger from './Hamburger'

import styles from "./Nav.module.styl"

const baseStyle = styles.nav
const activeStyle = [baseStyle, styles.isActive].join(' ')


export default class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false }
  }

  handleToggle = (ev) => {
    this.setState({ active: !this.state.active })
  }

  clicked = (ev) => {
    this.setState({ active: false })
  }

  isActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: styles.active }
      : {}
  }

  render() {
    return (
      <nav className={ this.state.active ? activeStyle : baseStyle }>
        <div className={ styles.screen } />
        <div className={ styles.mobileMenu }>
          <Hamburger
            handleToggle={ this.handleToggle }
            active={ this.state.active } />
        </div>
        <ul onClick={this.clicked}>
          <li><Link getProps={ this.isActive } to="/services/research-engineering">Data&nbsp;Science</Link></li>
          <li><Link getProps={ this.isActive } to="/services/data-foundations">Foundations</Link></li>
          <li><Link getProps={ this.isActive } to="/articles">Articles</Link></li>
          <li><Link getProps={ this.isActive } to="/about">Team</Link></li>
          <li><Link getProps={ this.isActive } to="/clients">Work</Link></li>
          <li><Calendly text="Get&nbsp;in&nbsp;touch" /></li>
        </ul>
      </nav>
    )
  }
}
