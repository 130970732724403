import React from 'react';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { Link } from 'gatsby'
import styles from "./Signup.module.styl"


export default class Signup extends React.Component {
  Apology = "Looks like we can't add you right now. Please send an email to hi@feldberlin.com and we will add you to the list manually. Sorry about this!"

  constructor(props) {
    super(props);
    this.state = { subscribed: false }
  }

  createSubscriber(props) {
    return addToMailchimp(props.email)
     .then((data) => { if(data.result === 'error') { throw new Error('nope.'); } })
     .catch((error) => { throw new Error(error); });
  }

  render() {
    if (this.state.subscribed) {
      return (
        <div className={ styles.signup }>
          <h3>Thanks for signing up.</h3>
          <p>
            The first newsletter should arrive within the next two weeks.
          </p>
        </div>
      )
    } else {
      return (
        <div className={ styles.signup }>
          { this.props.size == "short" &&
            <div>
              <h3>Subscribe to the FELD newsletter</h3>
              <p>
                Sign up to the FELD Newsletter. If you liked this article, you
                will probably like the newsletter, too.
              </p>
            </div>
          }

          { this.props.size == "long" &&
            <p>
              Sign up to the FELD Newsletter. <span>Get a biweekly view on machine
              intelligence and the related issues.</span>
            </p>
          }

          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Hmm, this doesn't look like an email address.")
                .required("We'll need your email address."),
            })}
            onSubmit={(values, actions) => {
              setTimeout(async () => {
                await this.createSubscriber(values)
                  .then(() => {
                    actions.setSubmitting(false);
                    this.setState({ subscribed: true });
                  })
                  .catch((err) => {
                    actions.setStatus({ error: this.Apology });
                    actions.setSubmitting(false);
                  })
              }, 400);
            }}
          >
            {({ isSubmitting, status }) => (
              <Form>
                <label htmlFor="email">Email Address</label>
                <Field name="email" type="email" autoComplete="off" onKeyDown={ () => { if(status) { status.error = '' } } } />
                <ErrorMessage name="email" component="span" />
                <span>{ status && status.error ? status.error : '' }</span>
                <span>{ isSubmitting ? 'subscribing...' : '' }</span>
                <input type="submit" value="Subscribe" disabled={ isSubmitting } />
              </Form>
            )}
          </Formik>
        </div>
      );
    }
  }
};
